body .driver-popover {
    font-family: "degular", sans-serif !important;
    font-weight: 400;
    font-style: normal;
    box-shadow: none;
  }
  
  body .driver-popover-title {
    font-family: "antique-olive-nord", sans-serif !important;
    text-transform: uppercase;
    font-weight: 400;
    font-style: normal;
  }
  
  body .driver-popover-description {
    font-family: "degular", sans-serif !important;
    font-weight: 400;
    font-style: normal;
  }
  
  body .driver-popover-footer button {
    font-family: "degular", sans-serif !important;
    font-weight: 400;
    font-style: normal;
  }