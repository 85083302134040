body .driver-popover {
  box-shadow: none;
  font-style: normal;
  font-weight: 400;
  font-family: degular, sans-serif !important;
}

body .driver-popover-title {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-family: antique-olive-nord, sans-serif !important;
}

body .driver-popover-description, body .driver-popover-footer button {
  font-style: normal;
  font-weight: 400;
  font-family: degular, sans-serif !important;
}
/*# sourceMappingURL=index.2f48c6d9.css.map */
